
//
//  Sweetalert2
//

.swal2-container {
  .swal2-title{
    font-size: 24px;
    font-weight: $font-weight-medium;
  }  
}

.swal2-popup{
  background-color: $card-bg;
}

.swal2-content{
  font-size: 16px;
}

.swal2-icon{
  &.swal2-question{
    border-color: $info;
    color: $info;
  }
  &.swal2-success {
    [class^=swal2-success-line]{
      background-color: $success;
    }

    .swal2-success-ring{
      border-color: rgba($success, 0.3);
    }
  }
  &.swal2-warning{
    border-color: $warning;
    color: $warning;
  }
}

.swal2-styled{
  &:focus{
    box-shadow: none;
  }
}

.swal2-progress-steps {
  .swal2-progress-step{
    background: $primary;
    &.swal2-active-progress-step{
      background: $primary;
      &~.swal2-progress-step, &~.swal2-progress-step-line{
        background: rgba($primary, 0.3);
      }
    }
  }

  .swal2-progress-step-line{
    background: $primary;
  }
}

.swal2-loader{
  border-color: $primary transparent $primary transparent;
}
