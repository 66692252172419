// 
// coming-soon.scss
//

.counter-number {
    font-size: 24px;
    font-family: $font-family-secondary;
    text-align: center;
    span {
        font-size: 14px;
        font-weight: $font-weight-normal;
        display: block;
        padding-top: 5px;
    }
    
}

.coming-box {
    float: left;
    width: 21%;
    padding: 14px 7px;
    margin: 0 12px 24px 12px;
    background-color: $card-bg;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgb(126 142 177 / 10%);
}

@media (max-width: 991.98px){
    .coming-box {
        width: 40% !important;
    }
}


.comingsoon-bg{
    background-image: url("../images/comingsoon-bg.jpg");
}