//
// Range slider
//

.irs {
    font-family: $font-family-base;
}

.irs--round {

    .irs-bar,
    .irs-to,
    .irs-from,
    .irs-single {
        background: $primary !important;
        font-size: 11px;
    }

    .irs-to,
    .irs-from,
    .irs-single {
        &:before {
            display: none;
        }
    }

    .irs-line {
        background: var(--#{$prefix}light);
        border-color: var(--#{$prefix}light);
    }

    .irs-grid-text {
        font-size: 11px;
        color: $gray-500;
    }

    .irs-min,
    .irs-max {
        color: $gray-500;
        background: var(--#{$prefix}light);
        font-size: 11px;
    }

    .irs-handle {
        border: 2px solid $primary;
        width: 16px;
        height: 16px;
        top: 29px;
        background-color: var(--#{$prefix}card-bg) !important;
    }
}