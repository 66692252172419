// 
// Extras pages.scss
//


// pricing

.pricing-box{
    padding: 30px 45px;
    position: relative;
    .plan{
       font-size: 34px;
    }
  }
    
   .pricing-badge {
    position: absolute;
    top: 0;
    z-index: 999;
    right: 0;
    width: 100%;
    display: block;
    font-size: 15px;
    padding: 0;
    overflow: hidden;
    height: 100px;
    .badge {
      float: right;
      transform: rotate(45deg);
      right: -67px;
      top: 17px;
      position: relative;
      text-align: center;
      width: 200px;
      font-size: 13px;
      margin: 0;
      padding: 7px 10px;
      font-weight: 500;
      color: $white;
      background: $danger;
    }
  }
  

/*********************
    Faqs
**********************/ 

.faq-nav-tabs{
    .nav-item{
        margin: 0px 8px;
    }
    .nav-link{
        text-align: center;
        margin-bottom: 8px;
        border: 2px solid var(--#{$prefix}border-color);
        color: var(--#{$prefix}body-color);
        .nav-icon{
            font-size: 40px;
            margin-bottom: 8px;
            display: block;
        }

        &.active{
            border-color: $primary;
            background-color: transparent;
            color: var(--#{$prefix}body-color);

            .nav-icon{
                color: $primary;
            }
        }
    }
}



// Errror pages
.error-title{
    font-size: 90px;
    .blink-infinite {
        animation: infinite-blink 2s infinite;
      }
}
  
  @keyframes infinite-blink {
    60%,
    80% {
      color: $success;
    }
    70%,
    100% {
      color: darken($dark, 15%);
    }
  }
/*# sourceMappingURL=app.min.css.map */


// Profile


.user-profile-img{
    position: relative;
    
    .overlay-content{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: rgba($success, 0.8);
        display: flex;
        height: 100%;
        color: rgba($white, 0.6);
        flex-direction: column;
    }
    .profile-img{
        width: 100%;
        height: 250px;
        object-fit: cover;
  
        @media (max-width:991px) {
            height: 160px;
        }
    }
  }
