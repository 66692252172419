// 
// authentication.scss
//

.auth-body-bg{
    background-color: $card-bg;
}

.auth-maintenance {
    background-image: url(../images/overlay-1.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.auth-error {
    background-image: url(../images/overlay-2.png);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
}

.authentication-bg{
    background-image: url("../images/authentication-bg.jpg");
    height: 100vh;
    background-size: cover;
    background-position: center;
    .bg-overlay{
        background-color: #292626;
    }

    @media (max-width: 991px) {
            display: none;
    }
}


.auth-input{
    .form-label{
    position: relative;
    left: 14px;
    top: 11px;
    line-height: 20px;
    background: $input-bg;
    margin: 0;
    padding: 0 7px;
    z-index: 1;
    }
    .form-control{
        padding: .9rem 1.2rem;
    }
}


.signin-other-title {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        right: 0;
        border-top: 1px dashed var(--#{$prefix}border-color);
        top: 10px;
    }

    .title {
        display: inline-block;
        position: relative;
        z-index: 9;
        background-color: $input-bg;
        padding: 2px 16px;
    }
}



.auth-logo{
    &.logo-light{
      display: $display-none;
    }
    &.logo-dark {
      display: $display-block;
    }
  }