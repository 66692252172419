// 
// Widgets.scss
// 


.social-info{
    position: relative;
    margin-top: -70px;
}



/* Activity */


.activity-checkout {
    list-style: none;

    .checkout-icon{
        position: absolute;
        top: -4px;
        left: -24px;
    }

    .checkout-item {
        position: relative;
        padding-bottom: 24px;
        padding-left: 35px;
        border-left: 2px solid var(--#{$variable-prefix}light);
        &:first-child {
            border-color: $primary;
            &:after {
                background-color: $primary;
            }
        }
        &:last-child {
            border-color: transparent;
        }
        &.crypto-activity{
            margin-left: 50px;
        }
        .crypto-date{
            position: absolute;
            top: 3px;
            left: -65px;
        }
    }
}
